@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
$font-primary: Nunito;

body {
  font-family: $font-primary;
}

#root {
  @apply bg-skin-default;
  @apply text-skin-base;
}

.app {
  @apply min-h-screen;
  @apply grid;

  grid-template-areas: "sidebar app-header" "sidebar content";
  grid-template-rows: min-content 1fr;
  grid-template-columns: min-content 1fr;

  > .app-content {
    @apply overflow-y-hidden overflow-x-auto;
    grid-area: content;
  }
}
