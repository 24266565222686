.loading-view {
  @apply flex justify-center items-center;
  @apply text-3xl;
  @apply mx-auto;

  &--overlay {
    @apply absolute inset-0;
    @apply z-10;

    > .overlay {
      @apply bg-black opacity-40;
      @apply absolute inset-0;
    }

    > .icon {
      @apply text-skin-inverted;
    }
  }
}
