@import "styles/common/spec-common";

.app-header {
  @apply flex items-center;
  @apply overflow-hidden;
  @apply sticky top-0;
  @apply duration-200;
  @apply bg-skin-top;
  @apply shadow-md;
  @apply w-full;
  grid-area: app-header;
  z-index: $header-z;
  height: $header-height;
  padding-left: $sidebar__hamburger-width;

  @screen lg {
    padding-left: $padding-desktop;
    padding-right: $padding-desktop;
  }

  &--show-text {
    @apply shadow-md;
  }

  > .app-header__title {
    @apply text-lg font-medium;
  }

  > .sidebar-hamburger {
    @apply flex justify-start;
    @apply w-10;
  }
}
